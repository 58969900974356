import { useContext, useState } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import { Trans } from 'next-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import PageBlock from '@/components/PageBlock';
import RequestRegisterModal from '@/components/blocks/RequestRegisterModal';

import styles from './applications-list-2.module.css';

const ApplicationBlock = ({ name, icon, href }) => {
  const [isRegOpen, setRegOpen] = useState(false);
  return (
    <div className={styles.block}>
      <img className={styles.icon} src={icon} width={44} height={44} alt={name} />
      <div className={styles.blockTitle} dangerouslySetInnerHTML={{ __html: name }}></div>
      {href ? (
        <Button viewStyle="secondary" className={styles.blockButton} as={Link} href={href} fullWidth>
          Подробнее
        </Button>
      ) : (
        <Button viewStyle="secondary" className={styles.blockButton} onClick={() => setRegOpen(true)} fullWidth>
          Подробнее
        </Button>
      )}
      <RequestRegisterModal
        open={isRegOpen}
        onClose={setRegOpen}
      />
    </div>
  );
};

const ApplicationsList2 = ({ items }) => {
  const { viewport } = useContext(ThemeContext);

  if (!items) return null;

  return (
    <PageBlock
      title={
        <Trans
          i18nKey="applications_forma"
          components={{ accent: <span /> }}
        />
      }
      className={styles.root}
    >
      {viewport === 'phone' ? (
        <Slider className={styles.slider} arrows={false} infinite={false} dots={true}>
          {items.map(({ button, ...item }, index) => (
            <div className={styles.item} key={`item_${index}`}>
              <ApplicationBlock {...item} />
            </div>
          ))}
        </Slider>
      ) : (
        <div className={styles.grid}>
          {items.map(({ button, ...item }, index) => (
            <div className={styles.item} key={`item_${index}`}>
              <ApplicationBlock {...item} />
            </div>
          ))}
        </div>
      )}
    </PageBlock>
  );
};

export default ApplicationsList2;
