import { useContext, useState } from 'react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ThemeContext } from '@forma/forma-ui-kit';
import MainBanner2 from '@/components/blocks/MainBanner2';
import ApplicationsList2 from '@/components/blocks/ApplicationsList2';
import MetaTags from '@/components/MetaTags';
import useMetaTags from '@/hooks/useMetaTags';
import { queries } from '@/helpers/api';
import getHeadersFromServer from '@/helpers/getHeadersFromServer';
import RequestDemoModal from '@/components/blocks/RequestDemoModal';
import SocialMediaSlider from '@/components/blocksMiniBuisness/SocialMediaSlider';
import Counts2 from '@/components/blocks/Counts2';
import AdvantagesBanner3 from '@/components/blocks/AdvantagesBanner3';
import RequestDemoBanner from '@/components/blocks/RequestDemoBanner';
import Cards from '@/components/blocks/Cards';

import styles from './Homepage.module.css';

export const getServerSideProps = async ({ req, res, params }) => {
  try {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=14400, stale-while-revalidate=10'
    );
    const mainData = await queries.getMainData(params.locale);
    const homeData = await queries.getPageData(params.locale, 'home');
    const tariffsData = await queries.getTariffsData(params.locale);

    const headersParse = await getHeadersFromServer(req);
    return {
      props: {
        initialState: {
          main: mainData,
        },
        home: homeData,
        tariffs: tariffsData,
        headersParse,
        ...(await serverSideTranslations(params.locale, ['common', 'uikit'])),
      },
    };
  } catch {
    return {
      notFound: true
    };
  }
};

const lkUrl = process.env.LK_URL;

const Home = ({ home, tariffs }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const metaData = {
    title: t('main_title'),
    description: t('main_description'),
    keywords: t('main_keywords'),
  };
  const { lang, viewport } = useContext(ThemeContext);
  useMetaTags({ lang, ...metaData });

  if (!home) return null;

  const isPhone = ['phone', 'tabletS'].includes(viewport);
  const { main_banner, advantages, counts, applications, media, automate, request_demo } = home;

  return (
    <div>
      <Head>
        <title>{metaData.title}</title>
        <MetaTags {...metaData} />
      </Head>

      <MainBanner2 {...main_banner} media={media} />
      {!isPhone && <Counts2 {...counts} />}
      {!isPhone && <SocialMediaSlider {...media} className={styles.media} marqueeClassName={styles.marquee} />}
      <Cards {...automate} />
      <AdvantagesBanner3 {...advantages} variant="white" />
      <ApplicationsList2 items={applications} />
      <RequestDemoBanner variant="white" {...request_demo} />

      {/* <CreateTemplatesSteps2 {...steps_create} /> */}
      {/* <CountsFilling
        {...counts}
        button={
          <Button
            as={Link}
            viewStyle="primary"
            className={styles.button}
            href={`${lkUrl}/register?lng=${lang}`}
          >
            Начать бесплатно
          </Button>
        }
      /> */}
      {/* <Results
        {...results}
        onClick={() => setIsOpen(true)}
        style={{ backgroundColor: 'var(--primary-color)', color: 'var(--color-white-primary)' }}
      />
      <AdvantagesBanner items={advantages} />
      <EconomyCalc /> */}
      {/* <RequestDemo2
        title="Запросите демонстрацию сервиса"
        successTitle={<>Вы записались на демонстрацию сервиса <span className="accent-text">Форма</span></>}
        subtitle="Заполните поля ниже, чтобы оставить заявку на демонстрацию сервиса с экспертом в формате видео-встречи. Проанализируем ваши бизнес-процессы и покажем работу платформы за 13 минут."
        buttonText={t('request_demo')}
        image={<Image className={styles.requestDemoImage} src="/images/demo-image-2.webp" width={506} height={370} alt="Форма - Демонстрация" />}
      /> */}

      <RequestDemoModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={<>Записаться на 15-ти минутную <span className="accent-text">презентацию</span></>}
        successTitle={<>Вы записались на демонстрацию сервиса <span className="accent-text">Форма</span></>}
        subtitle="Наш специалист проведет презентацию продукта в формате видео-встречи в удобное для вас время"
        buttonText="Назначить презентацию"
      />

      {/* <div className={styles.tariffs}><Tariffs items={tariffs} /></div> */}
      {/* <TemplatesBanner items={templates} /> */}
      {/* <Counts items={counts} /> */}
      {/* {isPhone ? <HowToUseBannerMobile items={how_to_use} /> : <HowToUseBanner items={how_to_use} />} */}
      {/* <Reviews {...reviews} /> */}
      {/* <HomepageTitle2 /> */}
      {/* {!isPhone && <HomepageTitle />} */}
      {/* <PromoBanner /> */}
      {/* <Questions items={questions} /> */}
    </div>
  );
};

export default Home;
