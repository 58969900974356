import { useState, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { Button, ButtonsContainer, Modal, ThemeContext } from '@forma/forma-ui-kit';
import PageBlock from '@/components/PageBlock';
import Video from '@/components/Video';
// import { RequestDemoButton } from '@/components/blocks/RequestDemo';
import RequestDemoModal2 from '@/components/blocks/RequestDemoModal2';
import { MainContext } from '@/context/MainContext';
import { analytics } from '@/helpers/analytics';

import styles from './main-banner-2.module.css';

const lkUrl = process.env.LK_URL;

const MainBanner2 = ({ title, description }) => {
  const { t } = useTranslation();
  const { main } = useContext(MainContext);
  const { lang, viewport } = useContext(ThemeContext);
  const [openVideo, setOpenVideo] = useState(false);
  const [isRequestOpen, setRequestOpen] = useState(false);

  const isPhone = viewport === 'phone';

  return (
    <PageBlock className={styles.root} variant="white">
      <div className={styles.requestCall}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{description}</p>
        <ButtonsContainer className={styles.buttons}>
          {main?.solutions.map(({ name, href, icon }) => (
            <Button
              as={Link}
              viewStyle="secondary"
              className={classNames(styles.button, styles.secondary, styles.topButton)}
              onClick={() => {
                analytics.clickTryTrial();
              }}
              href={href}
              icon={<ReactSVG src={icon} wrapper="span" />}
              key={name}
            >
              {name}
            </Button>
          ))}
        </ButtonsContainer>
      </div>
      <div className={styles.videoWrap}>
        {openVideo ? (
          <Video
            className={styles.video}
            src="https://static.forma.today/videos/Forma-Anim_1920-1080_All_5-9_H264.mp4"
            autoPlay
          />
        ) : (
          <Image
            className={styles.fillingScreen}
            src="/images/screen-filling.webp"
            alt="Заполнение шаблона"
            width={1146}
            height={577}
          />
        )}
        {!openVideo && (
          <div className={styles.videoOverlay}>
            <Button
              viewStyle="secondary"
              className={classNames(styles.button, styles.secondary, styles.topButton)}
              onClick={() => setOpenVideo(true)}
              icon={<ReactSVG src="/icons/play-2.svg" className={styles.playIcon} />}
            >
              {t('watch_video')}
            </Button>
          </div>
        )}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          as={Link}
          viewStyle="primary"
          className={styles.button}
          href={`${lkUrl}/register?lng=${lang}`}
          fullWidth={isPhone}
        >
          {t('start_free')}
        </Button>
        <Button
          // as={Link}
          viewStyle="secondary"
          className={classNames(styles.button, styles.secondary)}
          onClick={() => {
            setRequestOpen(true);
            analytics.clickTryTrial();
          }}
          fullWidth={isPhone}
        // href="/request"
        >
          {t('request_demo')}
        </Button>
      </ButtonsContainer>
      <div className={styles.notice}>
        Бесплатно 7 дней. Карта не требуется.
      </div>

      {/* <ClientWrap>
        <Modal
          open={openVideo}
          onClose={() => setOpenVideo(false)}
          size="large"
        >
          <Video
            className={styles.video}
            src="https://static.forma.today/videos/Forma-Anim_1920-1080_All_5-9_H264.mp4"
          />
        </Modal>
      </ClientWrap> */}
      <RequestDemoModal2
        open={isRequestOpen}
        onClose={setRequestOpen}
      />
    </PageBlock>
  );
};

export default MainBanner2;
